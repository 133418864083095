import type { DocumentReference, FieldValue, GeoPoint, Timestamp } from 'firebase/firestore'
import { serverTimestamp } from 'firebase/firestore'
import { getIdFromRef } from '../helpers/getIdFromRef'
import { NotificationInfoModel } from './NotificationInfoModel'
import { PlaceModel } from './PlaceModel'
import { SubsidyModel } from './SubsidyModel'

export class SupplierModel {
  approvedOrders: string | null
  arrivalTime: string | null
  arrivalTimeTo: string | null
  code: string | null
  company: DocumentReference | null
  deliverID: string | null
  deliveryRadius: number | null
  geoPoint: GeoPoint | null
  isActive: boolean
  isBuyOnline: string | null
  isPublicOffice: string | null
  isHomeDelivery: string | null
  isPayCash: string | null
  isPayOnline: string | null
  menu: string | null
  notificationInfo: NotificationInfoModel | null
  pendingOrders: string | null
  place: PlaceModel | null
  route: string | null
  sellsLimitPrice: number | null
  sellsLimitTime: Timestamp
  subsidy: SubsidyModel | null
  timeTable: string | null
  assignUsers: string | null
  isPickUp: string | null
  isCollectiveInvoice: string | null
  isOverflowInvoice: string | null
  deliveryCost: string | null
  tags: string | null
  position: string | null
  createdByUser: DocumentReference | null
  lastUpdateTime: FieldValue | null
  lastUpdateByUser: DocumentReference | null
  reference: DocumentReference
  deliver: string | null
  routeObject: string | null

  constructor(data, reference, deliver?, routeObject?) {
    this.arrivalTime = data.arrivalTime || null
    this.arrivalTimeTo = data.arrivalTimeTo || null
    this.sellsLimitTime = data.sellsLimitTime || serverTimestamp()
    this.code = data.code || null
    this.isActive = data.isActive || false
    this.company = data.company || null
    this.deliverID = data.deliverID || null
    this.geoPoint = data.geoPoint || null
    this.timeTable = data.timeTable || false
    this.isBuyOnline = data.isBuyOnline || false
    this.isPublicOffice = data.isPublicOffice || false
    this.pendingOrders = data.pendingOrders || 0
    this.approvedOrders = data.approvedOrders || 0
    this.menu = data.menu || null
    this.subsidy = data.subsidy
      ? new SubsidyModel(data.subsidy)
      : null
    this.place = data.place
      ? new PlaceModel(data.place)
      : null
    this.isPayOnline = data.isPayOnline || false
    this.isPayCash = data.isPayCash == null
      ? true
      : data.isPayCash
    this.route = data.route || null
    this.notificationInfo = new NotificationInfoModel(data.notificationInfo)

    this.isHomeDelivery = data.isHomeDelivery || false
    this.deliveryRadius = data.deliveryRadius
      ? Number(data.deliveryRadius)
      : null
    this.sellsLimitPrice = data.sellsLimitPrice
      ? Number(data.sellsLimitPrice)
      : null

    this.createdByUser = data.createdByUser || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null
    this.assignUsers = data.assignUsers || 0
    this.isPickUp = data.isPickUp || false

    this.isCollectiveInvoice = data.isCollectiveInvoice || false
    this.isOverflowInvoice = data.isOverflowInvoice || false
    this.deliveryCost = data.deliveryCost || null
    this.tags = data.tags || []
    this.position = data.position || null

    this.reference = reference || null
    this.deliver = deliver || null
    this.routeObject = routeObject || null
  }

  toMap() {
    return {
      approvedOrders: this.approvedOrders,
      arrivalTime: this.arrivalTime,
      arrivalTimeTo: this.arrivalTimeTo,
      code: this.code,
      company: this.company,
      deliverID: this.deliverID,
      deliveryRadius: this.deliveryRadius,
      geoPoint: this.geoPoint,
      isActive: this.isActive,
      isBuyOnline: this.isBuyOnline,
      isPublicOffice: this.isPublicOffice,
      isHomeDelivery: this.isHomeDelivery,
      isPayCash: this.isPayCash,
      isPayOnline: this.isPayOnline,
      menu: this.menu,
      notificationInfo: this.notificationInfo
        ? this.notificationInfo.toMap()
        : null,
      pendingOrders: this.pendingOrders,
      place: this.place
        ? this.place.toMap()
        : null,
      route: this.route,
      sellsLimitPrice: this.sellsLimitPrice,
      sellsLimitTime: this.sellsLimitTime,
      subsidy: this.subsidy
        ? this.subsidy.toMap()
        : null,
      timeTable: this.timeTable,
      assignUsers: this.assignUsers || 0,
      isPickUp: this.isPickUp || false,
      isCollectiveInvoice: this.isCollectiveInvoice || false,
      isOverflowInvoice: this.isOverflowInvoice || false,
      deliveryCost: this.deliveryCost || null,
      tags: this.tags || [],
      position: this.position || null,

      lastUpdateTime: this.lastUpdateTime || null,
      lastUpdateByUser: this.lastUpdateByUser || null,
    }
  }
}

export function mapSuppliers(routeRef) {
  return supplier => new SupplierModel(
    {
      ...supplier.data(),
      route: routeRef,
    },
    supplier.ref,
  )
}

export function mapSuppliersSubsidy(supplier) {
  return new SupplierModel(
    { ...supplier.data() },
    supplier.ref,
  )
}

export function mapSuppliersHomeDelivery(supplier) {
  return new SupplierModel(
    supplier.data(),
    supplier.ref,
  )
}

export function mapNotSentNotificationSuppliers(routes) {
  return supplier => new SupplierModel(
    supplier.data(),
    supplier.ref,
    null,
    routes.find(route => getIdFromRef(route.reference) === getIdFromRef(supplier.data().route)),
  )
}
